/* Portfolio Page */

/*
  FlipCards:
  https://www.w3schools.com/howto/howto_css_flip_card.asp
*/

.grid-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(365px, 400px));
  justify-content: center;
  gap: 20px;
}

.portfolio-card {
  perspective: 1000px;
}

.portfolio-card--inner {
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.portfolio-card--inner.flipped {
  transform: rotateY(180deg);
}

.portfolio-card--inner.flipped > .portfolio-card--front * {
  pointer-events: none;
}

.portfolio-card--front,
.portfolio-card--back {
  position: absolute;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  background-color: #383c4a;
  padding: 20px;
}

.portfolio-card--front {
  position: relative;
}

.portfolio-card--back {
  transform: rotateY(180deg);
  inset: 0;
}

.portfolio-card--back__section {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 230px 1fr;
}

.portfolio-card--back__section .technologies-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.portfolio-card--back__section strong {
  display: block;
  margin-bottom: 10px;
}

.portfolio-card--back__header {
  justify-content: flex-end;
  display: flex;
}

.portfolio-card--front__image {
  background-color: #7c818c;
  height: 240px;
}

.portfolio-card--front__information {
  background-color: rgba(0, 0, 0, 0.25);
  border-top-right-radius: 5px;
  position: absolute;
  cursor: pointer;
  height: 30px;
  width: 30px;
  right: 20px;
  top: 20px;
}

.portfolio-card--front__titlebar {
  margin-bottom: 26px;
}
