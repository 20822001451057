.home-into {
  text-align: center;
  margin-bottom: 40px;
}

.home-outro {
  text-align: center;
  align-self: center;
  margin-top: 20px;
}

.home-container {
  max-width: 430px;
  padding: 20px;
  background-color: #404552;
  margin-bottom: 20px;
}

.home-container > p {
  margin-bottom: 0;
}

.home-banner-figure {
  max-width: 550px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.home-description-module {
  justify-content: space-around;
}

@media only screen and (max-width: 740px) {
  .home-description-module svg {
    display: none;
  }
}
