*,
*::before,
*::after {
  box-sizing: border-box;
}

::selection {
  background: #ffd866;
  color: #000000;
}

::-moz-selection {
  background: #ffd866;
  color: #000000;
}

/* Scrollbar Width */
::-webkit-scrollbar {
  width: 5px;
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  background-color: #7c818c;
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  background-color: #add480;
}

@supports (scrollbar-color: black white) {
  * {
    scrollbar-color: #add480 #7c818c;
  }
}

body,
input,
button,
textarea {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #383c4a;
  color: #d9d9d9;
  line-height: 1.5;
  overflow: auto;
  margin: 0;
}

@supports (overflow: overlay) {
  body {
    overflow: overlay;
  }
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

h1 {
  margin: 0;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  color: #add480;
  font-size: 24px;
}

img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  object-position: center;
  margin-bottom: 6px;
}

ul {
  margin: 0;
  margin-bottom: 30px;
}

p,
label,
strong {
  font-size: 16px;
  line-height: 1.5;
  max-width: 60ch;
}

p {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 30px;
}

label,
strong {
  letter-spacing: 0.5px;
  font-weight: 700;
}

figcaption {
  font-size: 13px;
  text-align: center;
}

a {
  color: #5294e2;
  text-decoration: none;
}

a:hover,
.active {
  cursor: pointer;
  color: #e34d3a !important;
  fill: #e34d3a !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

figure {
  margin: 0;
}
