.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Opera and Firefox */
}

.rounded {
  border-radius: 5px;
}

.tag {
  background-color: #7c818c;
  color: #000000;
  font-weight: 800;
  font-size: 10px;
  cursor: default;
  padding: 0 4px;
}

.flex-available-height {
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
}

.flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-btw-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}