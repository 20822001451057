.contact-form {
  display: flex;
  position: fixed;
  flex-direction: column;
  background-color: #4b5162;
  transform: translateX(100vw);
  max-width: 360px;
  padding: 20px;
  width: 100%;
  z-index: 40;
  left: 50%;
  bottom: 0;
}

.contact-form.show {
  transform: translateX(80px);
}

@media only screen and (max-width: 910px) {
  .contact-form.show {
    transform: translateX(-50%);
  }
}

.contact-form textarea,
.contact-form input {
  display: block;
  color: #d9d9d9 !important;
  background-color: rgba(0, 0, 0, 0.25) !important;
  letter-spacing: 0.5px;
  font-size: 14px;
  margin-top: 2px;
  padding: 2px 4px;
  border: none;
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  color: #d9d9d9 !important;
  caret-color: #d9d9d9 !important;
  background-color: rgba(0, 0, 0, 0.25) !important;
  -webkit-box-shadow: 0 0 0 30px #383d49 inset !important;
  -webkit-text-fill-color: #d9d9d9 !important;
}

.contact-form input {
  height: 30px;
}

.contact-form input.valid {
  border: 1px solid green !important;
}

.contact-form textarea {
  height: 100px;
  resize: none;
}

.contact-form textarea:focus,
.contact-form input:focus {
  border: 1px solid #e34d3a;
  outline: none;
}

.contact-form button {
  letter-spacing: 0.5px;
  background-color: #5294e2;
  border-color: #5294e2;
  color: #d9d9d9;
  font-size: 14px;
  padding: 10px;
  width: 100%;
}

.contact-form button:hover,
.contact-form button:focus {
  border-color: #e34d3a;
  cursor: pointer;
  outline: none;
}

.contact-form button[disabled] {
  border-color: #5294e2;
  cursor: not-allowed;
  opacity: 0.5;
}

.form-group {
  margin-bottom: 20px;
}

.close-form {
  margin-left: auto;
}

.close-form svg {
  fill: #4b5162;
}
