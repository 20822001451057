.page-header {
  background-color: #4b5162;
  position: sticky;
  padding: 20px;
  z-index: 20;
  gap: 40px;
  top: 0;
}

@media only screen and (max-width: 652px) {
  .page-header {
    justify-content: center;
  }
}

.menu-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 20px;
}

.menu-list--socials svg {
  fill: #d9d9d9;
}

.menu-list--socials svg:hover {
  cursor: pointer;
  fill: #5294e2;
}

.menu-list--socials .contact-icon {
  fill: #ffd866;
}

.menu-list--socials .contact-icon:hover {
  fill: #e34d3a;
}

.menu-list--main a {
  font-weight: 800;
  letter-spacing: 0.5px;
  font-size: 17px;
}
