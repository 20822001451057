.close-icon {
  position: relative;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.close-icon::after {
  background-color: #ffd866;
  position: absolute;
  height: 60%;
  width: 100%;
  content: "";
  top: 4px;
}

.close-icon > svg {
  position: absolute;
  fill: #383c4a;
  z-index: 5;
}

.close-icon:hover::after {
  background-color: #e34d3a;
}
