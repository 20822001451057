.tutorial-listing + .tutorial-listing {
  margin-top: 20px;
}

.tutorial-listing {
  display: grid;
  fill: #d9d9d9;
  color: #d9d9d9;
  border: 3px solid #5294e2;
  background-color: #5294e2;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  column-gap: 20px;
  row-gap: 10px;
  padding: 17px;
}

@media only screen and (max-width: 652px) {
  .tutorial-listing {
    grid-template-columns: 1fr;
  }
  .tutorial-listing svg {
    display: none;
  }
}

.tutorial-listing:hover,
.tutorial-listing:focus {
  border-color: #e34d3a;
  cursor: pointer;
  outline: none;
}

.tutorial-listing:hover > * {
  color: #d9d9d9;
  fill: #d9d9d9;
}

.tutorial-listing__updated {
  white-space: nowrap;
}
