.status-badge {
  text-align: center;
  position: fixed;
  color: black;
  padding: 10px;
  width: 100%;
  z-index: 50;
  top: 0;
}

.status-badge.success {
  background-color: green;
}

.status-badge.failure {
  background-color: red;
}
