.search-container {
  margin-bottom: 40px;
}

.search-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 20px;
  row-gap: 10px;
}

@media only screen and (max-width: 320px) {
  .search-bar {
    display: none;
  }
}

.search-box {
  flex: 1;
  padding: 5px;
  display: flex;
  align-items: center;
  background-color: #4b5162;
  color: #d9d9d9;
  padding-left: 10px;
  max-width: 300px;
  gap: 10px;
}

.search-box input[type="search"] {
  background-color: rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  padding: 2px 4px;
  color: #d9d9d9;
  border: none;
  height: 25px;
  flex: 1;
}

.search-box input[type="search"]:focus {
  border: 1px solid #e34d3a;
  outline: none;
}
