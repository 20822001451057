.page-container {
  box-shadow: 0px 0px 1px 1px rgba(75, 81, 98, 0.5);
  background-color: #404552;
  margin: 40px auto;
  max-width: 900px;
  padding: 20px;
  width: 100%;
}

.content-container {
  margin: 40px 20px;
}

.content-main > h1 {
  margin-bottom: 20px;
}

.content-section {
  background: #383c4a;
  padding: 20px;
}
